<ng-container *ngIf="'text' === linkItem?.type">
    <h2 *ngIf="linkItem.classes.includes('title') && !linkItem.classes.includes('subtitle')" 
        class="{{linkItem.classes}}" 
        [innerHTML]="linkItem.text | safeHtml">
    </h2>
    <p *ngIf="!linkItem.classes.includes('title') || linkItem.classes.includes('subtitle')" 
        class="{{linkItem.classes}}" 
        [innerHTML]="linkItem.text | safeHtml">
    </p>
</ng-container>
<span *ngIf="'title' === linkItem?.type" class="{{linkItem.classes}}">
    <ng-container *ngFor="let titleText of linkItem.items">
        <ng-container *ngIf="'text' === titleText.type">
            <h3 *ngIf="titleText.classes.includes('title') && !titleText.classes.includes('subtitle')" 
                class="{{titleText.classes}}" 
                [innerHTML]="titleText.text | safeHtml">
            </h3>
            <p *ngIf="!titleText.classes.includes('title') || titleText.classes.includes('subtitle')"
                class="{{titleText.classes}}" 
                [innerHTML]="titleText.text | safeHtml">
            </p>
        </ng-container>
    </ng-container>
</span>
<img *ngIf="this.renderImage"
    [class]="linkItem.classes"
    [src]="linkItem.src || ''"
    [alt]="linkItem.altText"
    storyImage="true"
>