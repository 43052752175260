import { Component } from '@angular/core';
import { StoryAbstractComponent } from '../shared/story-abstract.component';
import { get } from 'lodash-es';
import { DomSanitizer } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
@Component({
  selector: 'pixie-revenue',
  templateUrl: '../shared/story.component.html',
  styleUrls: ['./revenue.component.scss']
})
export class RevenueComponent extends StoryAbstractComponent {
  constructor(sanitizer: DomSanitizer, cookieService: CookieService) {
    super(sanitizer, cookieService);
  }
  getTemplateBlockGroups(storyData) {
    // let templateBlockGroups = [];
    const templateBlockGroups = [
      // BLOCK GROUP 1
      {
          classes: 'two-thirds-desktop',
          anchorName: 'revenue-hero-block-group-1-anchor',
          personalizedLocationId: this.siteId + '-site-pzn-homepage-revenuemodule-left',
          blocks: [
              // narrowCard
              {
                  'analyticsTrackSeen': this.createAnalyticsTrackSeen(storyData, 1,
                      this.stripImageName(storyData.descriptions.narrowCard.media.backgroundDesktop)),
                  classes: 'half-mobile-landscape full-desktop story-block-1 story-block-row ' +
                      this.getCSSSectionClasses(
                          get(storyData, 'descriptions.narrowCard', false)
                      ),
                  itemsWrapped: {
                    background: [
                      this.getImageTemplate(
                        storyData.descriptions.narrowCard.media.backgroundDesktop,
                        'background visible-xs hidden-sm visible-md visible-lg'
                      ),
                      this.getImageTemplate(
                          storyData.descriptions.narrowCard.media.backgroundMobile,
                          'background hidden-xs visible-sm hidden-md hidden-lg'
                      )
                    ],
                    foreground: [
                      this.getTitleTemplate(storyData.descriptions.narrowCard)
                    ]
                  },
                  fontColor: get(
                      storyData,
                      'descriptions.narrowCard.sections.fontColor',
                      ''
                  )
              },
              // squareCard2
              {
                  analyticsTrackSeen: this.createAnalyticsTrackSeen(storyData, 2,
                      storyData.descriptions.squareCard2.sections.cta1AnalyticsLinkId +
                      '-' +
                      storyData.descriptions.squareCard2.sections.cta2AnalyticsLinkId),
                  classes: 'half-mobile-landscape half-desktop story-block-2 ' +
                      this.getCSSSectionClasses(
                          get(storyData, 'descriptions.squareCard2', false)
                      ),
                  itemsWrapped: {
                    foreground: [
                      this.getTextTemplate(storyData.descriptions.squareCard2),
                      this.getTextTemplate(storyData.descriptions.squareCard2, true, 'body'),
                      {
                          type: 'link',
                          classes: 'snowball-button snowball-button-theme-sky-blue ' +
                              'color-white',
                          href: storyData.descriptions.squareCard2.sections.cta1Link,
                          target: get(storyData, 'descriptions.squareCard2.sections.cta1Target', '_self'),
                          subtitle: storyData.descriptions.squareCard2.sections.subtitle,
                          title: storyData.descriptions.squareCard2.sections.title,
                          analyticsLinkId: this.getStoryAnalyticslinkId(storyData,
                              2,
                              storyData.descriptions.squareCard2.sections.cta1AnalyticsLinkId),
                          items: [
                              this.getTextTemplate(
                                  storyData.descriptions.squareCard2.sections.cta1Text
                              )
                          ]
                      },
                      {
                          type: 'link',
                          classes: 'snowball-button snowball-button-theme-white ' +
                              'color-sky-blue last',
                          href: storyData.descriptions.squareCard2.sections.cta2Link,
                          target: get(storyData, 'descriptions.square2.sections.cta2Target', '_self'),
                          ariaLabel: storyData.descriptions.squareCard2.sections.title + ' - '
                             + storyData.descriptions.squareCard2.sections.cta2Text,
                          analyticsLinkId: this.getStoryAnalyticslinkId(storyData,
                              2,
                              storyData.descriptions.squareCard2.sections.cta2AnalyticsLinkId),
                          items: [
                              this.getTextTemplate(
                                  storyData.descriptions.squareCard2.sections.cta2Text
                              )
                          ]
                      }
                    ]
                  }
              },
              // squareCard3
              {
                  'analyticsTrackSeen': this.createAnalyticsTrackSeen(storyData, 3,
                      this.stripImageName(storyData.descriptions.squareCard3.media.backgroundDesktop)),
                  classes: 'half-desktop story-block-3' +
                      'hidden-xs hidden-sm visible-md visible-lg ' +
                      this.getCSSSectionClasses(
                          get(storyData, 'descriptions.squareCard3', false)
                      ),
                  itemsWrapped: {
                    background: storyData.descriptions.squareCard3.media.cinemagraphMedia1 ? [
                            this.getPixiePlayerTemplate(storyData.descriptions.squareCard3)
                      ] : [
                        this.getImageTemplate(
                            storyData.descriptions.squareCard3.media.backgroundDesktop,
                            'background hidden-xs hidden-sm visible-md visible-lg'
                        ),
                        this.getImageTemplate(
                            storyData.descriptions.squareCard3.media.backgroundMobile,
                            'background hidden-xs hidden-sm hidden-md hidden-lg'
                        )
                    ]
                }
              }
          ]
      },
      // BLOCK GROUP 2
      {
          classes: 'one-third-desktop',
          anchorName: 'revenue-hero-block-group-2-anchor',
          personalizedLocationId: this.siteId + '-site-pzn-homepage-revenuemodule-right',
          blocks: [
              // squareCard1
              {
                  'analyticsTrackSeen': this.createAnalyticsTrackSeen(storyData, 4,
                      this.stripImageName(storyData.descriptions.squareCard1.media.backgroundDesktop)),
                  classes: 'half-mobile-landscape full-desktop story-block-4 story-block-row ' +
                      this.getCSSSectionClasses(
                          get(storyData, 'descriptions.squareCard1', false)
                      ),
                  itemsWrapped: {
                    background: storyData.descriptions.squareCard1.media.cinemagraphMedia1 ? [
                            this.getPixiePlayerTemplate(storyData.descriptions.squareCard1)
                      ] : [
                        this.getImageTemplate(
                            storyData.descriptions.squareCard1.media.backgroundDesktop,
                            'background visible-xs hidden-sm hidden-md hidden-lg'
                        ),
                        this.getImageTemplate(
                            storyData.descriptions.squareCard1.media.backgroundMobile,
                            'background hidden-xs visible-sm visible-md visible-lg'
                        )
                      ]
                  }
              },
              // squareCard4
              {
                  'analyticsTrackSeen': this.createAnalyticsTrackSeen(storyData, 5,
                      storyData.descriptions.squareCard4.sections.cta1AnalyticsLinkId),
                  classes: 'half-mobile-landscape full-desktop story-block-5 ' +
                      'needs-triangular-notch ' +
                      this.getCSSSectionClasses(
                          get(storyData, 'descriptions.squareCard4', false)
                      ),
                  itemsWrapped: {
                    foreground: storyData.descriptions.squareCard4.media.cinemagraphMedia1 ? [
                            this.getPixiePlayerTemplate(storyData.descriptions.squareCard4)
                      ] : [
                        this.getTextTemplate(storyData.descriptions.squareCard4),
                        this.getTextTemplate(storyData.descriptions.squareCard4, true, 'body'),
                        {
                            type: 'link',
                            classes: 'cta',
                            href: storyData.descriptions.squareCard4.sections.cta1Link,
                            target: get(storyData, 'descriptions.squareCard4.sections.cta1Target', '_self'),
                            subtitle: storyData.descriptions.squareCard4.sections.subtitle,
                            title: storyData.descriptions.squareCard4.sections.title,
                            ariaLabel: storyData.descriptions.squareCard4.sections.title + ' - '
                             + storyData.descriptions.squareCard4.sections.cta1Text,
                            analyticsLinkId: this.getStoryAnalyticslinkId(storyData,
                                5,
                                storyData.descriptions.squareCard4.sections.cta1AnalyticsLinkId),
                            items: [
                                this.getTextTemplate(
                                    storyData.descriptions.squareCard4.sections.cta1Text
                                )
                            ]
                        }
                    ]
                  }
              }
          ]
      }
    ];

    return templateBlockGroups;
  }

}
