import { Component, Input } from '@angular/core';
import { get } from 'lodash-es';
import { StoryAbstractComponent } from '../shared/story-abstract.component';
import { DomSanitizer } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
@Component({
  selector: 'pixie-fast-crosspromo',
  templateUrl: '../shared/story.component.html',
  styleUrls: ['./fast-crosspromo.component.scss']
})

export class FastCrosspromoComponent extends StoryAbstractComponent {
  @Input() analyticsTrackingEvent = (linkId, {}) => {};

  constructor(sanitizer: DomSanitizer, cookieService: CookieService) {
    super(sanitizer, cookieService);
  }

  getTemplateBlockGroups(storyData) {
    const templateBlockGroups = [{
      'anchorName': 'fast-cross-promo-narrow-block-group-1-anchor',
      'blocks': []
    }];

    const order = storyData.sourceOrder && storyData.sourceOrder.array 
    ? storyData.sourceOrder.array : storyData.sourceOrder;

    for (let i = 0; i < order.length; ++i) {
      if (storyData.sourceOrder[i] === 'sectionHeader') {
        storyData.sourceOrder.splice(i, 1);
        break;
      }
      const keyValue = order[i];
      const sizeAndTypePieces = keyValue.includes('_') ? keyValue.split('_') : [],
        size = sizeAndTypePieces[0] ?? '',
        typeRaw = sizeAndTypePieces[1] ?? '',
        type = typeRaw.replace(/[0-9]/g, ''),
        blockData = this._generateCrossPromoBlock(size, type, typeRaw, storyData, i);

      templateBlockGroups[0].blocks.push(blockData);
    }

    return templateBlockGroups;

  }

  /*
   * Construct Fast Cross Promo block based on the size and type
   * @param {String} size (vertical/square/rectangle/narrow/banner)
   * @param {String} type (iconcard/hovercard)
   * @param {String} typeRaw (ex: iconcard1)
   * @param {Object} storyData (CMS Cross Promo block data)
   * @param {Integer} blockIndex (for analytics)
   * @returns {Object} properly formatted Cross Promo block data
   */
  private _generateCrossPromoBlock(size, type, typeRaw, storyData, blockIndex) {
    const blockName = size + '_' + typeRaw,
      section = 'descriptions.' + blockName;
    let itemsToAdd = [];
    const block = {
      'analyticsTrackSeen': this.createAnalyticsTrackSeen(
        storyData, blockIndex, get(storyData, section + '.analyticsLinkId', false)
      ),
      'classes': 'story-block-' + type + ' story-block-' + size + ' ' +
        this.getCSSSectionClasses(
          get(storyData, section, false)
        ),
      'personalizedLocationId': get(storyData, section +
        '.sections.personalizedLocationId',
        ''),
        itemsWrapped: {
          background: [],
          foreground: [],
          ctas: []
        }
    };

    if (type === 'foreground-zoom') {
        itemsToAdd = this._generateCrossPromoBlockForegroundZoom(storyData, section);
    }

    for (let i = 0; i < itemsToAdd.length; i++) {
      const item = itemsToAdd[i];
      let itemContent = get(storyData, item.contentKey, false);

      if (!itemContent) {
        continue;
      }

      if (item.type === 'image') {
        itemContent = this.getImageTemplate(itemContent, item.classes);
      } else if (item.type === 'text') {
        itemContent = this.getTextTemplate(itemContent, false, item.classes);
      }
      itemContent.mainCTALink = storyData.descriptions[blockName].sections.cta1Link;

      if (item.classes.indexOf('background') === 0) {
        block.itemsWrapped.background.push(itemContent);
      } else {
        block.itemsWrapped.foreground.push(itemContent);
      }
    }

    block.itemsWrapped.ctas.push({
        type: 'link',
        classes: storyData.descriptions[blockName].sections.cta1Css,
        href: storyData.descriptions[blockName].sections.cta1Link,
        target: get(storyData, 'storyData.descriptions[blockName].sections.cta1Target', '_self'),
        subtitle: storyData.descriptions[blockName].sections.subtitle,
        title: storyData.descriptions[blockName].sections.title,
        analyticsLinkId: this.getStoryAnalyticslinkId(storyData,
            2,
            storyData.descriptions[blockName].sections.cta1AnalyticsLinkId),
        items: [
            this.getTextTemplate(storyData.descriptions[blockName].sections.cta1Text)
        ]
    });

    if (storyData.descriptions[blockName].sections.cta2Text) {
      block.itemsWrapped.ctas.push({
        type: 'link',
        classes: storyData.descriptions[blockName].sections.cta2Css,
        href: storyData.descriptions[blockName].sections.cta2Link,
        target: get(storyData, 'storyData.descriptions[blockName].sections.cta2Target', '_self'),
        ariaLabel: storyData.descriptions[blockName].sections.title + ' - '
            + storyData.descriptions[blockName].sections.cta2Text,
        analyticsLinkId: this.getStoryAnalyticslinkId(storyData,
            2,
            storyData.descriptions[blockName].sections.cta2AnalyticsLinkId),
        items: [
            this.getTextTemplate(
              storyData.descriptions[blockName].sections.cta2Text
            )
        ]
      });
    }

    return block;
  }

  /*
   * Construct Fast Cross Promo block with the foreground zoom effect
   * @param {Object} storyData (CMS Cross Promo block data)
   * @param {String} section
   * @returns {Object} properly formatted Cross Promo block data
   */
  private  _generateCrossPromoBlockForegroundZoom(storyData, section) {
    const itemsToAdd = [];
    if (get(storyData, section + '.media.textBlockLeft', false)) {
      itemsToAdd.push({
        type: 'link',
        contentKey: section + '.media.textBlockLeft',
        classes: 'background cta',
        href: get(storyData, section + '.media.textBlockLeft.ctaLink', false),
        target: get(storyData, section + '.media.textBlockLeft.ctaLink', '_self')
      });
    }
    if (get(storyData, section + '.media.backgroundDesktop', false)) {
      itemsToAdd.push({
        type: 'image',
        contentKey: section + '.media.backgroundDesktop',
        classes: 'background hidden-xs visible-sm visible-md visible-lg ' +
          'fade-out-on-hover'
      });
    }
    if (get(storyData, section + '.media.backgroundDesktopHover', false)) {
      itemsToAdd.push({
        type: 'image',
        contentKey: section + '.media.backgroundDesktopHover',
        classes: 'background hidden-xs hidden-sm visible-md visible-lg ' +
          'fade-in-on-hover'
      });
    }
    if (get(storyData, section + '.media.backgroundMobile', false)) {
      itemsToAdd.push({
        type: 'image',
        contentKey: section + '.media.backgroundMobile',
        classes: 'background visible-xs hidden-sm hidden-md hidden-lg'
      });
    }
    if (get(storyData, section + '.media.icon', false)) {
      itemsToAdd.push({
        type: 'image',
        contentKey: section + '.media.icon',
        classes: 'icon-img'
      });
    }
    if (get(storyData, section + '.sections.title', false)) {
      itemsToAdd.push({
        type: 'text',
        contentKey: section + '.sections.title',
        classes: 'title'
      });
    }
    if (get(storyData, section + '.sections.subtitle', false)) {
      itemsToAdd.push({
        type: 'text',
        contentKey: section + '.sections.subtitle',
        classes: 'subtitle',
      });
    }
    if (get(storyData, section + '.sections.linkText', false)) {
      itemsToAdd.push({
        type: 'text',
        contentKey: section + '.sections.linkText',
        classes: 'pseudo-link-text',
      });
    }
    if (get(storyData, section + '.media.media1', false)) {
      itemsToAdd.push({
        type: 'image',
        contentKey: section + '.media.media1',
        classes: 'foreground-img'
      });
    }
    if (get(storyData, section + '.sections.disclaimer', false)) {
      itemsToAdd.push({
        type: 'text',
        contentKey: section + '.sections.disclaimer',
        classes: 'disclaimer'
      });
    }

    return itemsToAdd;
  }
}
