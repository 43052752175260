<h3 *ngIf="'text' === item?.type && item.classes.indexOf('title') !== -1 && item.classes.indexOf('subtitle') === -1" [class]="item.classes" role="button" tabindex="0" [innerHTML]="item.text | safeHtml" (click)="openMainCTA(item)"></h3>

<p *ngIf="'text' === item?.type && item.classes.indexOf('subtitle') !== -1" [class]="item.classes" role="button" tabindex="0" [innerHTML]="item.text | safeHtml" (click)="openMainCTA(item)"></p>

<img
    *ngIf="renderImage && !isFastIcon"
    [class]="item.classes"
    [src]="item.src || ''"
    [alt]="item.altText"
    storyImage="true"
    role="button" 
    tabindex="0"
    (click)="openMainCTA(item)"
>

<img
    *ngIf="renderImage && isFastIcon"
    [class]="item.classes"
    [src]="item.src || ''"
    [alt]="item.altText"
    storyImage="true"
    (click)="openMainCTA(item)"
>

<span *ngIf="'title' === item?.type && item.items[0].text" class="{{item.classes}}" [style.color]="fontColor">
    <ng-container *ngFor="let titleText of item.items">
        <p *ngIf="'text' === titleText.type" class="{{titleText.classes}}" role="button" tabindex="0" [innerHTML]="titleText.text | safeHtml"></p>
    </ng-container>
</span>

<span *ngIf="'pixie-player' === item?.type" title="{{item.alt}}">
    <a *ngIf="item.href" href="{{item.href | absTrimmer:true:true}}">
        <span [innerHTML]="item.frameSrc"></span>
    </a>
    <span *ngIf="!item.href" [innerHTML]="item.frameSrc"></span>
</span>


<a
    *ngIf="'link' === item?.type"
    [href]="item.href | absTrimmer:true:true"
    [class]="item.classes"
    [target]="item.target ? item.target : '_self'"
    name="{{item.analyticsLinkId}}"
    [attr.data-interstitial]="item.interstitial"
    [attr.aria-label]="item.ariaLabel || anchorAriaLabel"
    (click)="analyticsTrackingClick($event)"
>
<!-- (click)="storyBlockControllerVm.universalLayoutService.routeCheck($event)" -->
    <div class="background-wrapper" *ngIf="item.itemsWrapped?.background">
        <pixie-story-link-item *ngFor="let item of item.itemsWrapped.background" [linkItem]="item"></pixie-story-link-item>
    </div>
    <div class="foreground-wrapper" *ngIf="item.itemsWrapped?.foreground">
        <pixie-story-link-item *ngFor="let item of item.itemsWrapped.foreground" [linkItem]="item"></pixie-story-link-item>
    </div>
    <ng-template [ngIf]="item.items">
        <pixie-story-link-item *ngFor="let item of item.items" [linkItem]="item"></pixie-story-link-item>
    </ng-template>
</a>

<div *ngIf="'wrapper' === item?.type" class="{{item.classes}}">
    <span *ngFor="let wrappedItem of item.items">
        <a
            *ngIf="'link' === wrappedItem?.type"
            [class]="wrappedItem.classes"
            [href]="wrappedItem.href | absTrimmer:true:true"
            [target]="wrappedItem.target ? wrappedItem.target : '_self'"
            [name]="wrappedItem.analyticsLinkId"
            [attr.data-interstitial]="wrappedItem.interstitial"
        >
        <!-- (click)="storyBlockControllerVm.universalLayoutService.routeCheck($event)" -->
            <span *ngFor="let linkItem of wrappedItem.items">
                <font-icon
                    *ngIf="'icon' === linkItem?.type"
                    [class]="linkItem.classes"
                    name="{{linkItem.icon.icon}}"
                    htmlentity="{{linkItem.icon.htmlentity}}"
                    size="{{linkItem.icon.size}}"
                    title="{{linkItem.icon.title}}"
                >
                </font-icon>

                <p *ngIf="'text' === linkItem?.type" class="{{linkItem.classes}}" role="button" tabindex="0" [innerHTML]="linkItem.text | safeHtml"></p>
            </span>
        </a>
    </span>
</div>