import { Component, Input, OnInit } from '@angular/core';
import { VisibleMqService } from '@app/services/visible-mq/visible-mq.service';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'pixie-story-block-item',
  templateUrl: './story-block-item.component.html',
  styleUrls: ['./story-block-item.component.scss'],
})
export class StoryBlockItemComponent implements OnInit {
  @Input() analyticsTrackingEvent = (linkId, {}) => {};
  @Input() item: any = {
    type: '',
  };
  renderImage = false;
  isFastIcon = false;
  anchorAriaLabel = '';

  constructor(private mqService: VisibleMqService) {}

  ngOnInit() {
    this.setAnchorAriaLabel();
    if (this.item.type === 'image') {
      if (this.item.classes.match(/(^|\s)(visible|hidden)-\S*/g)) {
        this.mqService.currentMediaChanged$
          .pipe(debounceTime(50))
          .subscribe((currMedia) => this.mqHandler(currMedia.className));
        this.mqHandler(this.mqService.currentMedia.className);
      } else {
        const icons = ['first-icon', 'second-icon', 'third-icon', 'fourth-icon'];
        if (icons.includes(this.item.classes)) {
          this.isFastIcon = true;
        }
        this.renderImage = true;
      }
    }
  }

  private setAnchorAriaLabel() {
    let title = '';
    let subtitle = '';

    if (this.item.title) {
      title = this.removeHtmlTags(this.item.title);
    }

    const foregroundItem = this.item.itemsWrapped?.foreground?.[0];

    if (foregroundItem && foregroundItem.classes.includes('title')) {
      title = this.removeHtmlTags(foregroundItem.text || foregroundItem.items?.[0]?.text);
      
      const subtitleItem = foregroundItem.items?.[1];
      if (subtitleItem && subtitleItem.classes.includes('subtitle')) {
        subtitle = ', ' + this.cleanString(subtitleItem.text);
      }
    }
    if (this.item.type === 'link') {
      const linkText = this.item.items[0]?.text;
      this.anchorAriaLabel = linkText + ' - ' + title + subtitle;
    }
  }

  openMainCTA(item) {
    if (item.mainCTALink) {
      window.location.href = item.mainCTALink;
    }
  }


  removeHtmlTags(inputString: string = '') {
    // Use a regular expression to remove HTML tags
    let result = inputString.replace(/<[^>]*>/g, ' ');
    result = result.replace(/&nbsp;/g, ' ');

    return result;
  }

  cleanString(input) {
      return input.replace(/<img[^>]*>/, '').trim();
  }

  analyticsTrackingClick = () => {
    if (this.analyticsTrackingEvent) {
      this.analyticsTrackingEvent(this.item.analyticsLinkId, {});
    }
  }

  mqHandler(mqClassName): void {
    this.renderImage = this.item.classes.indexOf(mqClassName) > -1;
  }
}
