import { Injectable } from '@angular/core';
import { WindowSizeEnum } from '@app/shared/services/window-size/window-size.enum';
import { fromEvent, Observable } from 'rxjs';
import { debounceTime, map } from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class WindowSizeService {
    private readonly mobileMaxWidth = 767;
    private readonly tabletMaxWidth = 1023;
    private readonly resizeObservable$: Observable<WindowSizeEnum>;

    constructor() {
        this.resizeObservable$ =
            fromEvent(window, 'resize')
                .pipe(
                    debounceTime(300),
                    map(() => this.getDevice())
                );
    }

    getDevice(): WindowSizeEnum {
        const width = window.innerWidth;
        if (width <= this.mobileMaxWidth) {
            return WindowSizeEnum.Mobile;
        } else if (width <= this.tabletMaxWidth) {
            return WindowSizeEnum.Tablet;
        } else {
            return WindowSizeEnum.Desktop;
        }
    }

    /**
     * Returns an observable that emits the current device type (WindowSizeEnum)
     * whenever the window is resized.
     *
     * @returns {Observable<WindowSizeEnum>} An observable emitting the device type as a string.
     */
    onResize(): Observable<WindowSizeEnum> {
        return this.resizeObservable$;
    }

}
