<div id="homepage-top">
  <div id="quick-quote-container">
    <wdpr-lodging-quickquote 
      [config]="utilsConfig"
      [analyticsTrackingEvent]="analyticsTrackingEvent"
      [isFast]="fastStyle"
      [isFastCollapsible]="fastQQCollapsible"></wdpr-lodging-quickquote>
  </div>
  <pixie-hero
    [id]="id"
    [title]="title"
    [ctas]="ctas"
    [class]="siteId"
  ></pixie-hero>
  <park-hours [analyticsTrackingEvent]="analyticsTrackingEvent"></park-hours>
  <div class="stories-rounded-spacer"></div>
  <div *ngIf="!isMobileOrTablet" class="stories-rounded-spacer desktop"></div>
</div>
<pixie-stories appDeepLink [analyticsTrackingEvent]="analyticsTrackingEvent"></pixie-stories>
