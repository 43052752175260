<!-- <app-story-block [items]="storyData" [name]="name" (click)="updateName()"></app-story-block> -->

<hr class="sparkle-line" aria-hidden="true">

<h2 *ngIf="(storyData.sectionHeader && storyData.sectionHeader.label)"
    role="button" 
    tabindex="0"
    class="section-header {{storyData.sectionHeader.alignment}}">{{storyData.sectionHeader.label}}</h2>

<div class="story-block-group-wrapper">
    <div
        *ngFor="let blockGroup of storyData.blockGroups"
        data="blockGroup"
        class="story-block-group {{ blockGroup.classes | translateSizeClass }}"
        [attr.p13n-location]="blockGroup.personalizedLocationId ? blockGroup.personalizedLocationId : null">

        <span id="{{blockGroup.anchorName}}"></span>
        <div
            *ngFor="let block of blockGroup.blocks"
            [attr.anchor-name]="blockGroup.anchorName"
            [attr.p13n-location]="block.personalizedLocationId ? block.personalizedLocationId : null"
            [attr.wdpr-analytics-track-seen]="block.analyticsTrackSeen"
            class="story-block {{ block.classes | translateSizeClass }}"
            appStoryFadeIn appTrackSeen>
            
            <ng-template [ngIf]="block.itemsWrapped" [ngIfElse]="unwrapped">
                <span class="items-wrapped"></span>
                <div class="background-wrapper">
                    <pixie-story-block-item
                        class="story-block-item pixie-story-block-item" 
                        *ngFor="let blockItem of block.itemsWrapped.background" 
                        [analyticsTrackingEvent]="analyticsTrackingEvent"
                        [item]="blockItem">
                    </pixie-story-block-item>
                </div>
                <div class="foreground-wrapper">
                    <pixie-story-block-item 
                        class="story-block-item pixie-story-block-item" 
                        *ngFor="let blockItem of block.itemsWrapped.foreground" 
                        [analyticsTrackingEvent]="analyticsTrackingEvent"
                        [item]="blockItem">
                    </pixie-story-block-item>
                    <div class="cta-holder">
                        <pixie-story-block-item 
                            class="story-block-item pixie-story-block-item" 
                            *ngFor="let blockItem of block.itemsWrapped.ctas" 
                            [analyticsTrackingEvent]="analyticsTrackingEvent"
                            [item]="blockItem">
                        </pixie-story-block-item>
                    </div>
                </div>
            </ng-template>
            <ng-template #unwrapped>
                <pixie-story-block-item 
                    class="story-block-item " 
                    *ngFor="let blockItem of block.items" 
                    [analyticsTrackingEvent]="analyticsTrackingEvent"
                    [item]="blockItem">
                </pixie-story-block-item>
            </ng-template>

            <span class="dynamicStyles"
                *ngIf="getDynamicStyles(block.classes)" 
                [innerHTML]="getDynamicStyles(block.classes)">
            </span>

        </div>

    </div>
</div>