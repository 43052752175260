export const DEFAULT_TEXT = {
    "dlr": {
        "en-us": {
            "todaysParkHours": "Today's Park Hours",
            "closedToday": "Closed Today",
            "to": "to"
        },
        "es-us": {
            "todaysParkHours": "Horarios de los Parques de Hoy",
            "closedToday": "Cerrado Hoy",
            "to": "a"
        },
        "en-ca": {
            "todaysParkHours": "Today's Park Hours",
            "closedToday": "Closed Today",
            "to": "to"
        }
    },
    "wdw": {
        "en-us": {
            "todaysParkHours": "Today's Park Hours",
            "closedToday": "Closed Today",
            "to": "to"
        },
        "es-us": {
            "todaysParkHours": "Horarios de los Parques de Hoy",
            "closedToday": "Cerrado Hoy",
            "to": "a"
        },
        "en-ca": {
            "todaysParkHours": "Today's Park Hours",
            "closedToday": "Closed Today",
            "to": "to"
        },
        "fr-ca": {
            "todaysParkHours": "Heures d’ouverture",
            "closedToday": "Fermé aujourd’hui",
            "to": "à"
        },
        "es-ar": {
            "todaysParkHours": "Horarios de los Parques de Hoy",
            "closedToday": "Cerrado Hoy",
            "to": "a"
        },
        "es-mx": {
            "todaysParkHours": "Horarios de los Parques de Hoy",
            "closedToday": "Cerrado Hoy",
            "to": "a"
        },
        "es-pe": {
            "todaysParkHours": "Horarios de los Parques de Hoy",
            "closedToday": "Cerrado Hoy",
            "to": "a"
        },
        "es-co": {
            "todaysParkHours": "Horarios de los Parques de Hoy",
            "closedToday": "Cerrado Hoy",
            "to": "a"
        },
        "es-cl": {
            "todaysParkHours": "Horarios de los Parques de Hoy",
            "closedToday": "Cerrado Hoy",
            "to": "a"
        },
        "pt-br": {
            "todaysParkHours": "Horário de funcionamento dos Parques hoje",
            "closedToday": "Fechado hoje",
            "to": "até"
        }

    }
};
