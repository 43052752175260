import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ResourceBundleService } from '@app/services/resource-bundle/resource-bundle.service';
import { ConfigService } from '@core/config.service';
import { get } from 'lodash';
import { HeroContent, getHeroContentMode, getMediaEngineId } from '@app/shared/utils/media-engine';
import { RedirectionService } from "@app/services/redirection/redirection.service";
import { Brands } from "@app/shared/utils/utils.enum";
import { SanitizerService } from "@app/services/sanitizer/sanitizer.service";

@Component({
    selector: 'pixie-hero',
    templateUrl: './hero.component.html',
    styleUrls: ['./hero.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class HeroComponent implements OnInit {
    @Input() id: string;
    @Input() titleMobile: any;
    @Input() titleDesktop: any;
    @Input() headerMobile: any;
    @Input() headerDesktop: any;
    @Input() ctasMobile: object[];
    @Input() ctasDesktop: object[];
    @Input() src: any;
    @Input() dataType: string;

    siteId: string;
    isMobile: boolean = false;
    isFast: boolean = false;
    hasFirstCTA: boolean = false;
    hasDesktopBackgroundVideo: boolean = false;
    hasMobileBackgroundVideo: boolean = false;
    mediaKeyBase: string = 'heroes[0].media.';
    mediaKeyBaseLegacy: string = 'heroes[0].descriptions.';
    isOnFullscreen: boolean = false;

    constructor(
        private resourceBundle: ResourceBundleService,
        private configService: ConfigService,
        private redirectionService: RedirectionService,
        private sanitizerService: SanitizerService
    ) {
        this.siteId = this.configService.getValue('siteId');
        this.isMobile = 'ontouchstart' in window && window.screen.width < 768;
    }

    ngOnInit() {
        this.ctasMobile = [
            {
                alt: '',
                analyticsLinkId: '',
                title: '',
            },
            {
                alt: '',
                analyticsLinkId: '',
                analyticsLinkIdFullscreen: '',
                title: '',
                ctaURL: '',
            },
        ];
        this.ctasDesktop = [
            {
                alt: '',
                analyticsLinkId: '',
                title: '',
            },
            {
                alt: '',
                analyticsLinkId: '',
                analyticsLinkIdFullscreen: '',
                title: '',
                ctaURL: '',
            },
        ];

        window['heroMedia'] = [];
        this.resourceBundle.get('heroes').then(data => {
            const descriptions = data.heroes[0].descriptions;
            // Add data for CTAs
            if (/\bFast\b/i.test(data.heroes[0].name)) {
                this.isFast = true;

                this.headerMobile = this.sanitizerService.decodeHTML(descriptions?.mobileVideoTitle?.sections?.header);
                this.headerDesktop = this.sanitizerService.decodeHTML(descriptions?.videoTitle?.sections?.header);
                this.titleMobile = this.sanitizerService.decodeHTML(descriptions?.mobileVideoTitle?.sections?.title);
                this.titleDesktop = this.sanitizerService.decodeHTML(descriptions?.videoTitle?.sections?.title);

                const analyticsSiteId = this.siteId.toUpperCase();
                this.mediaKeyBase = this.mediaKeyBaseLegacy;
                let firstCtaDesktop = descriptions?.videoFirstCTA?.sections;
                let firstCtaMobile = descriptions?.mobileVideoFirstCTA?.sections;
                let detailsCtaDesktop = descriptions?.videoDetailsCTA?.sections;
                let detailsCtaMobile = descriptions?.mobileVideoDetailsCTA?.sections;

                if (get(data, 'heroes[0].descriptions.videoFirstCTA', false) && get(data, 'heroes[0].descriptions.mobileVideoFirstCTA', false)) {
                    this.hasFirstCTA = true;
                    this.ctasMobile[0] = {
                        alt: this.sanitizerService.decodeHTML(firstCtaMobile.alt),
                        analyticsLinkId: firstCtaMobile.analyticsLinkId,
                        title: this.sanitizerService.decodeHTML(descriptions?.mobileVideoFirstCTA?.sections?.title),
                        target: get(data, 'heroes[0].descriptions.mobileVideoFirstCTA.sections.link', '_self'),
                        icon: firstCtaMobile.icon,
                        ctaURL: firstCtaMobile.ctaURL
                    };
                    this.ctasDesktop[0] = {
                        alt: this.sanitizerService.decodeHTML(firstCtaDesktop.alt),
                        analyticsLinkId: firstCtaDesktop.analyticsLinkId,
                        title: this.sanitizerService.decodeHTML(firstCtaDesktop.title),
                        target: get(data, 'heroes[0].descriptions.videoFirstCTA.sections.link', '_self'),
                        icon: firstCtaDesktop.icon,
                        ctaURL: firstCtaDesktop.ctaURL
                    };
                }

                this.ctasMobile[1] = {
                    alt: this.sanitizerService.decodeHTML(detailsCtaMobile.alt),
                    analyticsLinkId: `${analyticsSiteId}_HPMP_Billboard_${detailsCtaMobile.analyticsLinkId}_Link`,
                    analyticsLinkIdFullscreen: `${analyticsSiteId}_HPMP_InVideo_${detailsCtaMobile.analyticsLinkId}_Link`,
                    title: this.sanitizerService.decodeHTML(detailsCtaMobile.title),
                    target: get(data, 'heroes[0].descriptions.mobileVideoDetailsCTA.link', '_self'),
                    icon: descriptions?.mobileVideoDetailsCTA?.sections?.icon,
                    ctaURL: detailsCtaDesktop.ctaURL
                };
                this.ctasDesktop[1] = {
                    alt: this.sanitizerService.decodeHTML(detailsCtaDesktop.alt),
                    analyticsLinkId: `${analyticsSiteId}_HPMP_Billboard_${detailsCtaDesktop.analyticsLinkId}_Link`,
                    analyticsLinkIdFullscreen: `${analyticsSiteId}_HPMP_InVideo_${detailsCtaDesktop.analyticsLinkId}_Link`,
                    title: this.sanitizerService.decodeHTML(detailsCtaDesktop.title),
                    target: get(data, 'heroes[0].descriptions.videoDetailsCTA.link', '_self'),
                    icon: descriptions?.videoDetailsCTA?.sections?.icon,
                    ctaURL: detailsCtaDesktop.ctaURL
                };

                //make hero not tabbable
                var checkHero = setInterval(function() {
                    const hero = document.getElementsByClassName('me-media-video')[0];
                    if (hero) {
                        clearInterval(checkHero);
                        hero.setAttribute('tabindex', '-1');
                        document.getElementsByClassName('me-video-play-pause-overlay')[0].setAttribute('tabindex', '-1');
                    }
                }, 1000);

            } else {
                this.titleMobile = descriptions?.mobileVideoTitle?.sections?.title;
                const isWdwOrDlr = this.siteId === Brands.WDW || this.siteId === Brands.DLR;
                const heroContent = getHeroContentMode(data);
                const showMobileVideoHkdl = get(data, 'heroes[0].media.mobileVideoDetail', false) || '';
                const showVideoWdwDlr = heroContent === HeroContent.ImageWithVideo || heroContent === HeroContent.VideoAutoplay;

                if (showMobileVideoHkdl || showVideoWdwDlr)
                {
                    this.hasMobileBackgroundVideo = true;
                    this.ctasMobile[0] = {
                        alt: this.sanitizerService.decodeHTML(descriptions?.mobileVideoPlayCTA?.sections?.alt),
                        analyticsLinkId: descriptions?.mobileVideoPlayCTA?.sections?.analyticsLinkId,
                        title: this.sanitizerService.decodeHTML(descriptions?.mobileVideoPlayCTA?.sections?.title),
                        target: get(data, 'heroes[0].descriptions.mobileVideoPlayCTA.sections.target', '_self'),
                    };
                }

                this.titleDesktop = descriptions?.videoTitle?.sections?.title;

                if (get(data, 'heroes[0].descriptions.videoPlayCTA.sections', false) && (isWdwOrDlr ? showVideoWdwDlr : true))  {
                    this.hasDesktopBackgroundVideo = true;
                    this.ctasDesktop[0] = {
                        alt: this.sanitizerService.decodeHTML(descriptions?.videoPlayCTA?.sections?.alt),
                        analyticsLinkId: descriptions?.videoPlayCTA?.sections?.analyticsLinkId,
                        title: this.sanitizerService.decodeHTML(descriptions?.videoPlayCTA?.sections?.title),
                        target: get(data, 'heroes[0].descriptions.videoPlayCTA.sections.target', '_self'),
                    };
                }

                let ctaDesktop = descriptions.videoDetailsCTA;
                ctaDesktop.alt = ctaDesktop?.sections?.alt;
                let ctaMobile = descriptions.mobileVideoDetailsCTA;
                ctaMobile.alt = ctaMobile?.sections?.alt;
                const analyticsSiteId = this.siteId.toUpperCase();

                // val.sections.source adds support for legacy wdw/dlr media
                if (isWdwOrDlr) {
                    ctaDesktop = descriptions?.videoDetailsCTA?.sections;
                    ctaDesktop.link = ctaDesktop?.ctaURL;
                    this.mediaKeyBase = this.mediaKeyBaseLegacy;
                    ctaMobile = descriptions?.mobileVideoDetailsCTA?.sections;
                    ctaMobile.link = ctaMobile?.ctaURL;
                }

                this.ctasMobile[1] = {
                    alt: this.sanitizerService.decodeHTML(ctaMobile?.alt),
                    analyticsLinkId: `${analyticsSiteId}_HPMP_Billboard_${ctaMobile.analyticsLinkId}_Link`,
                    analyticsLinkIdFullscreen: `${analyticsSiteId}_HPMP_InVideo_${ctaMobile.analyticsLinkId}_Link`,
                    title: this.sanitizerService.decodeHTML(ctaMobile.title),
                    target: get(data, 'heroes[0].descriptions.mobileVideoDetailsCTA.target', '_self'),
                    ctaURL: ctaDesktop.link
                };

                this.ctasDesktop[1] = {
                    alt: this.sanitizerService.decodeHTML(ctaDesktop?.alt),
                    analyticsLinkId: `${analyticsSiteId}_HPMP_Billboard_${ctaDesktop.analyticsLinkId}_Link`,
                    analyticsLinkIdFullscreen: `${analyticsSiteId}_HPMP_InVideo_${ctaDesktop.analyticsLinkId}_Link`,
                    title: this.sanitizerService.decodeHTML(ctaDesktop?.title),
                    target: get(data, 'heroes[0].descriptions.videoDetailsCTA.target', '_self'),
                    ctaURL: ctaDesktop.link
                };
            }
        });
    }

    enterFullscreen(event: Event) {
        event.preventDefault();
        const appComponentMediaEngineId = getMediaEngineId('appComponent', 1);
        const appComponentMediaEngineInstance = window[appComponentMediaEngineId];
        if (!appComponentMediaEngineInstance) {
            window['initConfigOverrides']();
        }

        if (appComponentMediaEngineInstance.config.callbacks.customFullscreenEnter) {
            appComponentMediaEngineInstance.config.callbacks.customFullscreenEnter();
            this.isOnFullscreen = true;
        }
    }

    redirectToUrl(event) {
        this.isOnFullscreen = this.redirectionService.redirectToUrl(event, this.isOnFullscreen, () => this.getCtaUrl());
    }

    getCtaUrl(): string {
        return this.redirectionService.getCtaUrl(this.ctasMobile, this.ctasDesktop);
    }

    onButtonClick(href) {
        window.location.href = href;
    }
}


declare global {
  interface Window {
    MEConfig: any;
    ME:any;
  }
}
